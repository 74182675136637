import { render, staticRenderFns } from "./Prev.vue?vue&type=template&id=040fc4a9&scoped=true"
import script from "./Prev.vue?vue&type=script&lang=js"
export * from "./Prev.vue?vue&type=script&lang=js"
import style0 from "./Prev.vue?vue&type=style&index=0&id=040fc4a9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040fc4a9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/var/www/html/js/productlisting/components/common/BaseIcon.vue').default})
