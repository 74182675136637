import { render, staticRenderFns } from "./default.vue?vue&type=template&id=a5ff4a68&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=a5ff4a68&prod&lang=scss&module=true"
import style1 from "./default.vue?vue&type=style&index=1&id=a5ff4a68&prod&lang=scss&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "a5ff4a68",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/var/www/html/js/productlisting/components/templates/TheHeader.vue').default,AlertsList: require('/var/www/html/js/productlisting/components/common/AlertsList.vue').default,TheFooter: require('/var/www/html/js/productlisting/components/templates/TheFooter.vue').default,TheLoading: require('/var/www/html/js/productlisting/components/templates/TheLoading.vue').default})
