// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerScrollTopBtn_knZT8{align-items:center;border-radius:50%;bottom:50px;box-shadow:0 .3rem 1.2rem rgba(0,0,0,.15);color:var(--primary-main-darker);display:flex;flex-direction:column;font-weight:600;height:48px;justify-content:center;line-height:20px;position:fixed;right:10px;text-align:center;transition:opacity .6s ease-in-out;width:48px;z-index:9}.footerScrollTopBtn_knZT8.is_boutique_ogGtw{color:#000}.footerScrollTopBtn_knZT8.is_boutique_ogGtw svg{fill:#8a6301}.footerScrollTopBtn_knZT8.footerScrollTopBtn_knZT8{background-color:var(--gray-5)}@media only screen and (min-width:768px){.footerScrollTopBtn_knZT8{bottom:40px;right:40px}}.footerScrollTopBtn_knZT8 svg{color:var(--primary-support);margin:-4px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerScrollTopBtn": "footerScrollTopBtn_knZT8",
	"is_boutique": "is_boutique_ogGtw"
};
module.exports = ___CSS_LOADER_EXPORT___;
