import { render, staticRenderFns } from "./google.vue?vue&type=template&id=1e0a7fc8&scoped=true"
import script from "./google.vue?vue&type=script&lang=js"
export * from "./google.vue?vue&type=script&lang=js"
import style0 from "./google.vue?vue&type=style&index=0&id=1e0a7fc8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e0a7fc8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StaticHeader: require('/var/www/html/js/productlisting/components/google/StaticHeader.vue').default,StaticFooter: require('/var/www/html/js/productlisting/components/google/StaticFooter.vue').default})
