// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_KG1pd{display:flex;flex-wrap:wrap;padding:1.2rem 1.6rem}@media only screen and (min-width:768px){.container_KG1pd{flex-wrap:nowrap;padding:1.6rem 2.4rem}}@media only screen and (min-width:1600px){.container_KG1pd{padding:1.6rem 6rem}}@media only screen and (min-width:1264px){.containerDesktop_dqfJ6{display:grid;grid-template-columns:19.9rem 23.4rem 1fr 36rem;grid-gap:2.4rem;align-items:end}}.icons_hud24{display:grid;grid-auto-flow:column;grid-column-gap:2rem;align-items:flex-end;justify-content:start;margin-left:auto}@media only screen and (min-width:768px){.icons_hud24{align-items:center}}@media only screen and (min-width:1264px){.icons_hud24{grid-column-gap:3.2rem}}.containerDesktop_dqfJ6 .icons_hud24{grid-column-gap:3.2rem;min-width:0;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_KG1pd",
	"containerDesktop": "containerDesktop_dqfJ6",
	"icons": "icons_hud24"
};
module.exports = ___CSS_LOADER_EXPORT___;
