const state = () => ({
  defaultParams: {
    page: 1,
    filter: 'ALL',
    sortBy: 'RECOMMENDED',
  },
});

const mutations = {
  SET_PAGE(state, page) {
    state.defaultParams.page = page;
  },
  SET_FILTER(state, filter) {
    state.defaultParams.filter = filter;
  },
  SET_SORT(state, sortBy) {
    state.defaultParams.sortBy = sortBy;
  },
};

const actions = {
  updatePage({ commit }, page) {
    commit('SET_PAGE', page);
  },
  updateFilter({ commit }, filter) {
    commit('SET_FILTER', filter);
  },
  updateSort({ commit }, sortBy) {
    commit('SET_SORT', sortBy);
  },
};

const getters = {
  defaultParams: (state) => state.defaultParams,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
