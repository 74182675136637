// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quantity_\\+vYiU{display:flex;gap:16px}.remove_Ihwri{font-size:14px;line-height:20px}.remove_Ihwri,.remove_Ihwri:hover{color:var(--primary-main)}.remove_Ihwri:hover{-webkit-text-decoration:underline;text-decoration:underline}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quantity": "quantity_+vYiU",
	"remove": "remove_Ihwri"
};
module.exports = ___CSS_LOADER_EXPORT___;
