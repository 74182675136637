
import respUtility from '@/mixins/respUtility';

/**
 * The on site premium protection popup
 * @category [Dialogs, Onsite]
 * @component
 * @example <caption>Dialog usage (see code)</caption>
 * <ProtectionPlanPopupMainContent @show-additional="toggle" :chosen="chosen"
 * />
 * @events ['show-additional', 'set-variant']
 */
export default {
  name: 'ProtectionPlanPopupMainContent',
  mixins: [respUtility],
  props: {
    /** @prop {Array} [:items="items"] - The protection items */
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    /** @prop {Object} [:chosen="chosen"] - The chosen plans */
    chosen: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      list: [
        'All stains & spills',
        'Accidental rips, tears, seam separation',
        'Hardware & mechanism malfunctions',
        '24/7 customer support',
        'Professional in-home service',
        '$0 deductible, easy reimbursements',
      ],
    };
  },
  computed: {
    /** Onsite protection possibility */
    isProtectionPlan() {
      return this.items.some((i) => i.protectionPlan);
    },
    isChanged() {
      if (!this.isProtectionPlan) {
        return true;
      }
      return this.items.some((item) => {
        const id = item?.protectionPlan?.id;
        if (id) {
          return this.chosen[item.itemId] !== id;
        }
        return Boolean(this.chosen[item.itemId]);
      });
    },
    chosenForEmit() {
      const result = {};
      Object.entries(this.chosen).forEach(([itemId, planId]) => {
        const item = this.items.find((i) => i.itemId === itemId);
        if (item?.pplanSubitems && item.pplanSubitems.length) {
          // eslint-disable-next-line no-return-assign
          item.pplanSubitems.forEach((i) => result[i.itemId] = planId);
        } else {
          result[itemId] = planId;
        }
      });
      return result;
    },
    isBoutiquePage() {
      return this.$store.state.metaData?.addons?.head?.isBoutique || false;
    },
  },
  methods: {
    removeProtections() {
      const data = this.items.reduce((acc, item) => {
        if (item?.pplanSubitems && item.pplanSubitems.length) {
          // eslint-disable-next-line no-return-assign
          item.pplanSubitems.forEach((i) => acc[i.itemId] = null);
        } else {
          acc[item.itemId] = null;
        }
        return acc;
      }, {});
      this.$emit('remove', data);
    },
  },
};
