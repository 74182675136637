import {
  email, helpers, minLength, required,
} from 'vuelidate/lib/validators';

/**
 * Get maximum characters for the input
 * @param inputName {String}
 * @return {Number}
 */
const getAllowedMaximumCharacters = function getAllowedMaxCharacters(inputName) {
  const allowedMaximumCharacters = {
    email: 100,
    name: 50,
    password: 15,
    fixes: 5,
    zip_code: 5,
    company_name: 50,
    address: 100,
    city: 20,
  };
  return allowedMaximumCharacters[inputName] || 50;
};

/**
 * Custom validator for vuelidate - check maxLength according to the input's name
 * @param inputName {String}
 */
const maxLengthByInputName = (inputName) => helpers.withParams(
  { type: 'maxLength', max: getAllowedMaximumCharacters(inputName) },
  (value) => helpers.len(value) <= getAllowedMaximumCharacters(inputName),
);

/**
 * Custom validator for vuelidate - check whitespaces on the both ends of a string
 * @param string {String}
 */
const whiteSpacing = (string) => string.length === string.trim().length;

const passwordValidation = () => ({
  required,
  minLength: minLength(6),
  maxLength: maxLengthByInputName('password'),
  whiteSpacing,
});

const emailValidation = () => ({
  required,
  email,
  maxLength: maxLengthByInputName('email'),
});
const nameValidation = () => ({
  required,
  maxLength: maxLengthByInputName('name'),
});

const maxLengthMessage = (n) => `Maximum allowed characters ${n === 1 ? 'is' : 'are'} ${n}`;
const whiteSpacingMessage = 'Please clean leading or trailing spaces';
const requiredMessage = 'This is a required field.';

const validationMixin = {
  data() {
    return {
      captchaError: null,
    };
  },
  computed: {
    zipCodeErrors() {
      const errors = [];
      if (!this.$v.zipCode?.$dirty) { return errors; }
      if (!this.$v.zipCode.minLength) {
        errors.push(`ZIP Code must have at least ${this.$v.zipCode.$params?.minLength?.min} symbols.`);
      }
      if (!this.$v.zipCode.checkValid) {
        errors.push('ZIP Code failed validation.');
      }
      if (!this.$v.zipCode.required) {
        errors.push('ZIP Code is required.');
      }
      return errors;
    },
    ratingErrors() {
      const errors = [];
      if (!this.$v.rating?.$dirty) { return errors; }
      if (!this.$v.rating.between) {
        errors.push('The rating must be between 1 and 5.');
      }
      if (!this.$v.rating.required) {
        errors.push('Please select one of each of the ratings above.');
      }
      return errors;
    },
    requiredErrors() {
      return (name) => {
        const errors = [];
        if (!this.$v[name]?.$dirty) { return errors; }
        if (!this.$v[name].required) {
          errors.push(requiredMessage);
        }
        return errors;
      };
    },
  },
  methods: {
    validateForm(validators) {
      const errorsArray = [];
      const entries = Object.entries(validators);
      entries.forEach((entry) => {
        const [name, errors] = entry;
        if (errors.length) {
          errorsArray.push({ name, errors });
        }
      });
      this.errors = errorsArray;
    },
    checkIsInputInvalid(input) {
      if (!input) { return false; }
      return input.$invalid || false;
    },
    validatePassword(input, handler) {
      const innerHandler = (errors) => {
        if (!input) { return; }
        if (!input.minLength) {
          // ToDo return message after removing magento AuthPopup
          // errors.push(`Password should be at least ${input.$params?.minLength?.min} characters long.`);
          errors.push(
            `Please ensure your password has between 
            ${input.$params?.minLength?.min} and ${input.$params?.maxLength?.max} characters.`,
          );
        }
        if (!input.whiteSpacing) {
          errors.push(whiteSpacingMessage);
        }
        if (!input.maxLength) {
          // ToDo return message after removing magento AuthPopup
          // errors.push(maxLengthMessage(input.$params?.maxLength?.max));
          errors.push(
            `Please ensure your password has between 
            ${input.$params?.minLength?.min} and ${input.$params?.maxLength?.max} characters.`,
          );
        }
        if (!input.required) {
          errors.push(requiredMessage);
        }
        if (typeof handler === 'function') {
          handler(errors);
        }
      };
      return this.getValidatorErrors(input, innerHandler) || [];
    },
    validateEmail(input, handler) {
      const innerHandler = (errors) => {
        if (!input) { return; }
        if (!input.email) {
          errors.push('Please enter a valid email address.');
        }
        if (!input.required) {
          errors.push(requiredMessage);
        }
        if (!input.maxLength) {
          errors.push(maxLengthMessage(input.$params?.maxLength?.max));
        }
        if (typeof handler === 'function') {
          handler(errors);
        }
      };
      return this.getValidatorErrors(input, innerHandler) || [];
    },
    validateName(input, handler) {
      const innerHandler = (errors) => {
        if (!input) { return; }
        if (!input.required) {
          errors.push(requiredMessage);
        }
        if (!input.maxLength) {
          errors.push(maxLengthMessage(input.$params?.maxLength?.max));
        }
        if (typeof handler === 'function') {
          handler(errors);
        }
      };
      return this.getValidatorErrors(input, innerHandler) || [];
    },
    validateRequired(input, handler) {
      const innerHandler = (errors) => {
        if (!input) { return; }
        if (!input.required) {
          errors.push(requiredMessage);
        }
        if (typeof handler === 'function') {
          handler(errors);
        }
      };
      return this.getValidatorErrors(input, innerHandler) || [];
    },
    getValidatorErrors(input, handler) {
      const errors = [];
      if (!input?.$dirty) { return errors; }
      if (typeof handler === 'function') {
        handler(errors);
      }
      if (!(Array.isArray(errors) && errors.length) && this.checkIsInputInvalid(input)) {
        errors.push('');
      }
      return errors || [];
    },
    /**
         * Find an input in the first ref with errors and focus on in.
         * Dependency: this.$refs
         * @param namesArray {Array} - inputs names in the correct order ['email', 'password'].
         * Use them for refs and validators keys.
         * @param validators {Object} - {password: this.passwordErrors, email: this.emailErrors} form errors.
         * Use input names as keys.
         */
    focusOnErrorInput(namesArray, validators) {
      const findFullArrayInValues = function findFullArrayInValuesFunction(name, obj) {
        return Object.entries(obj).find(([key, value]) => {
          if (Array.isArray(value)) {
            return value.length && key === name;
          }
          if (typeof value === 'object') {
            return findFullArrayInValues(name, value);
          }
          return false;
        });
      };
      const inputName = namesArray.find((name) => findFullArrayInValues(name, validators));
      this.focusOnInput(inputName);
    },
    /**
         * Find an input and focus on in.
         * Dependency: this.$refs
         * @param inputName {String} - input's name. Use it for refs and validators keys.
         */
    focusOnInput(inputName) {
      if (inputName && this.$refs) {
        const input = this.$refs[inputName]?.$el?.querySelector('input');
        if (input) {
          input.focus();
        }
      }
    },
    baseValidator(value, rules) {
      const errors = [];
      rules.forEach((rule) => {
        const result = rule(value);
        if (result !== true) {
          errors.push(result);
        }
      });
      return errors;
    },
    /** @TODO: check recaptcha */
    async checkCaptcha() {
      try {
        this.captchaError = null;
        const token = await this.$recaptcha?.getResponse();
        if (!token) {
          throw new Error('Failed to execute');
        }
        return token;
      } catch (e) {
        if (e === 'Failed to execute') {
          this.captchaError = 'Please fill up the captcha.';
        } else {
          this.captchaError = 'Something goes wrong with the captcha. Please try again later.';
        }
      }
      return null;
    },
  },
};

export {
  maxLengthByInputName, whiteSpacing, passwordValidation, emailValidation, nameValidation,
};

export default validationMixin;
