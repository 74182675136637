import Vue from 'vue';
/* eslint-disable import/no-extraneous-dependencies */
import { VPagination } from 'vuetify/lib';

const BasePagination = {
  name: 'BasePagination',
  extends: VPagination,
  props: {
    hideLastPage: {
      type: Boolean,
      default: false,
    },
    isReviewsPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      const totalVisible = parseInt(this.totalVisible, 10);

      if (totalVisible === 0 || Number.isNaN(this.length) || this.length > Number.MAX_SAFE_INTEGER) {
        return [];
      }

      if (this.hideLastPage) {
        if (this.value === 1 || this.value === 2) {
          return this.range(1, Math.min(5, this.length));
        }
        if (this.value === this.length || this.value === this.length - 1) {
          return this.range(this.length - 4, this.length);
        }
        const start = Math.max(1, this.value - 2);
        const end = Math.min(this.length, this.value + 2);
        return this.range(start, end);
      }

      const maxLength = Math.min(
        Math.max(0, totalVisible) || this.length,
        Math.max(0, this.maxButtons) || this.length,
        this.length,
      );

      if (this.length <= maxLength) {
        return this.range(1, this.length);
      }
      const even = totalVisible % 2 === 0;
      const middle = even ? totalVisible / 2 : Math.floor(totalVisible / 2);
      const left = even ? middle : middle + 1;
      const right = this.length - middle;
      const start = 1;
      if (left - this.value >= 0) {
        return [...this.range(start, Math.max(1, totalVisible - 2)), '...', this.length];
      } if (this.value - right >= (even ? 1 : 0)) {
        const rangeLength = totalVisible - 3;
        const rangeStart = this.length - rangeLength;
        return [start, '...', ...this.range(rangeStart, this.length)];
      }
      const rangeLength = Math.max(1, totalVisible - 4);
      const rangeStart = rangeLength === 1 ? this.value : this.value - Math.ceil(rangeLength / 2) + start;
      return [start, '...', ...this.range(rangeStart, rangeStart + rangeLength - 1), '...', this.length];
    },
  },
  mounted() {
    if (this.isReviewsPage) {
      this.updateButtonTitles();
    }
  },
  methods: {
    updateButtonTitles() {
      const prevButton = this.$el.querySelector('[aria-label="Previous page"]');
      const nextButton = this.$el.querySelector('[aria-label="Next page"]');

      if (prevButton) {
        prevButton.setAttribute('title', 'Previous');
      }
      if (nextButton) {
        nextButton.setAttribute('title', 'Next');
      }
    },
  },
};

Vue.component('BasePagination', BasePagination);
