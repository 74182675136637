// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (min-width:768px){.additional_FCI5B{align-items:center;border-top:.1rem solid hsla(0,0%,100%,.5);display:flex;width:100%;grid-gap:3.2rem}}@media only screen and (min-width:1264px){.additional_FCI5B{padding:1.6rem 2.4rem}}@media only screen and (min-width:1600px){.additional_FCI5B{padding:1.6rem 6rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"additional": "additional_FCI5B"
};
module.exports = ___CSS_LOADER_EXPORT___;
