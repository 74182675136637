// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

export const TOGGLE_WISH_LIST = gql`
    mutation toggleWishList ($productId: Int!, $wishlistId: Int) {
        wishlist {
            toggleWishlistProduct(productId: $productId, wishlistId: $wishlistId) {
                message
                success
            }
        }
    }
`;

export const LOGIN = gql`
    mutation login ($username: String!, $password: String!, $token: String) {
        customer {
            login(username: $username, password: $password, token: $token) {
                message
                success
            }
        }
    }
`;

export const SIGNUP = gql`
    mutation signup($customerInput: CustomerInput!, $token: String!) {
        customer {
            signup(customerInput: $customerInput, token: $token) {
                message
                success
            }
        }
    }
`;

export const RESET_PW = gql`
    mutation resetPW($email: String!) {
      customer {
        resetPW(email: $email) {
          message
          success
        }
      }
    }
`;

export const DISPLAY_NO_STOCK_BLOCK = gql`
    mutation Customer ($productId: ID!, $email: String!) {
        customer {
            submitEmail(productId: $productId, email: $email) {
                isAlreadySubmitted
                success
                message
            }
        }
    }
`;

export const CHANGE_HELPFUL_REVIEW = gql`
    mutation Customer ($reviewId: ID!, $status: HelpfulEnum) {
        customer {
            helpfulReview(reviewId: $reviewId, status: $status) {
                helpfulCount
                message
                success
            }
        }
    }
`;

export const TRACK_CJ_EVENT = gql`
    mutation trackEvent($cjevent: String) {
        cjeventMutations{
            trackEvent(
                cjevent: $cjevent,
            ) {
                success
            }
        }
    }
`;
