import IconArrowPrev from '~/components/common/icon/vuetify/Prev.vue';
import IconArrowNext from '~/components/common/icon/vuetify/Next.vue';
import IconStarFill32x32 from '~/components/common/icon/StarFill-32x32.vue';
import IconStarHalf32x32 from '~/components/common/icon/StarHalf-32x32.vue';
import IconClose from '~/components/common/icon/vuetify/Close.vue';
import IconStarOutlined24x24 from '~/components/common/icon/StarOutlined-24x24.vue';
import IconStarOutlinedGray16x15 from '~/components/common/icon/StarOutlinedGray-16x15.vue';

export default {
  theme: {
    themes: {
      light: {
        primary: '#0E259A',
        secondary: '#435FEE',
        accent: '#F25D0D',
        error: '#EF5350',
      },
    },
  },
  breakpoint: {
    thresholds: {
      sm: 768,
      md: 1280,
      lg: 1920,
    },
  },
  icons: {
    values: {
      close: {
        component: IconClose,
      },
      next: {
        component: IconArrowNext,
      },
      prev: {
        component: IconArrowPrev,
      },
      star: {
        component: IconStarFill32x32,
      },
      starHalf: {
        component: IconStarHalf32x32,
      },
      starOutlined: {
        component: IconStarOutlined24x24,
      },
      starOutlinedGray: {
        component: IconStarOutlinedGray16x15,
      },
    },
  },
};
